import React, { useState } from 'react';
import { navigate, Link } from 'gatsby';
import { handleLogin, isLoggedIn } from '../services/auth';
import Row from '../components/row/row';
import styled from 'styled-components';
import classNames from 'classnames';

const ForgotPasswordP = styled.p`
  .wysiwyg-content & {
    margin-top: 10px;
  }
`;

export function Login() {
  const [fields, setFields] = useState({
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState({
    email: {
      error: false,
      message: '',
    },
    password: {
      error: false,
      message:
        'Sorry, the password or email is wrong. If you forgot your password use the link below.',
    },
  });

  const handleFieldChange = (event) => {
    setFields({
      ...fields,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    handleLogin(fields, errors, setErrors);
  };

  if (isLoggedIn()) {
    navigate(`/app/profile`);

    return null;
  }

  return (
    <Row className="wysiwyg-content">
      <h1>Log in</h1>

      <form
        method="post"
        onSubmit={(event) => {
          handleSubmit(event);
        }}
        style={{ maxWidth: '425px' }}
      >
        <label>
          Email
          <input type="email" name="email" onChange={handleFieldChange} />
        </label>

        <label className={classNames({ 'h-error': errors.password.error })}>
          Password
          <input type="password" name="password" onChange={handleFieldChange} />
          {errors.password.error && (
            <StyledMessageSmall error={errors.password.error}>
              {errors.password.message}
            </StyledMessageSmall>
          )}
        </label>

        <input type="submit" className="button" value="Log In" />

        <ForgotPasswordP>
          <Link to="/forgot-password">Forgot Password?</Link>
        </ForgotPasswordP>
      </form>
    </Row>
  );
}

const StyledMessageSmall = styled.small`
  color: ${({ error }) => (error ? 'var(--c-error)' : 'var(--c-gray)')};

  strong {
    color: var(--c-black);
    font-size: 16px;
  }
`;
