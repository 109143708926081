import coaching from '../../assets/images/signs/coaching.png';
import revival from '../../assets/images/signs/revival.png';
import royals from '../../assets/images/signs/royals.png';
import stairway from '../../assets/images/signs/stairway-to-heaven.png';
import stardust from '../../assets/images/signs/stardust.png';
import success from '../../assets/images/signs/success.png';
import death from '../../assets/images/signs/til-death-do-us-party.png';
import wolf from '../../assets/images/signs/wolf-pack.png';
import pledge from '../../assets/images/signs/pledge-patch.png';
import instructions from '../../assets/images/signs/instructions.png';

export const signs = [
  {
    name: 'Instructions',
    url: '/map-instructions',
    image: instructions,
    position: [2, 2],
    widthTablet: '200px',
    width: '350px',
  },
  {
    name: 'Pledge Patch',
    url: '/class/pledge-patch',
    image: pledge,
    position: [10, 29],
    width: '150px',
  },
  {
    name: 'Til Death Do Us Party',
    url: '/class/til-death-do-us-party',
    image: death,
    position: [10, 51],
    width: '150px',
  },
  {
    name: 'Stardust',
    url: '/class/stardust',
    image: stardust,
    position: [17, 80],
    width: '150px',
  },
  {
    name: 'Revival',
    url: '/class/revival',
    image: revival,
    position: [41, 50],
    width: '150px',
  },
  {
    name: 'Stairway To Heaven',
    url: '/class/stairway-to-heaven',
    image: stairway,
    position: [43, 24],
    width: '150px',
  },
  {
    name: 'Wolf Pack',
    url: '/class/wolf-pack',
    image: wolf,
    position: [49, 66],
    width: '150px',
  },
  {
    name: 'Royals',
    url: '/class/royals',
    image: royals,
    position: [78, 73],
    width: '150px',
  },
  {
    name: 'Coaching',
    url: '/class/coaching',
    image: coaching,
    position: [78, 11],
    width: '150px',
  },
  {
    name: 'Success',
    url: '/success',
    image: success,
    position: [78, 39],
    width: '130px',
  },
];
