import React from 'react';
import Row from '../row/row';
import Map from './map';
import Card from '../card';
import map from '../../assets/images/candyland.jpg';
import { GetClasses } from '../../hooks/classes';
import { getUser } from '../../services/auth';
import cn from 'classnames';
import SEO from '../seo';
import styled from 'styled-components';
import { fetcher } from '../../utils/fetcher';
import useSWR from 'swr';

const GridUl = styled.ul`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  margin-bottom: 50px;
`;

const MapsContainer = styled('div')`
  padding: 0;
  margin-bottom: 50px;
`;

export default function Classes() {
  const classes = GetClasses() || [];
  const { data, error, isLoading } = useSWR(
    `${process.env.GATSBY_ADMIN_URL}/users/${getUser().userId}`,
    fetcher
  );

  if (error) {
    return <h2>Failed to load.</h2>;
  }

  if (isLoading) {
    return <h2>Loading...</h2>;
  }

  return (
    <>
      <SEO title="Classes" description="" />

      <MapsContainer>
        <Map />
      </MapsContainer>

      <Row>
        <GridUl>
          {classes
            .sort((a, b) => a.order - b.order)
            .map(({ id, strapiId, slug, title, card, tasks }) => {
              const image = card?.url || map;
              const isClassCompleted = () => {
                return data.completed_classes.some((item) => item.id === strapiId);
              };

              const completedTasks = data.completed_tasks.filter(
                (item) => item.class === strapiId
              ).length;

              return (
                <li key={id}>
                  <Card
                    title={title}
                    link={`/class/${slug}`}
                    image={image}
                    className={cn({ complete: isClassCompleted() })}
                    totalTasks={tasks.length}
                    completedTasks={completedTasks}
                  />
                </li>
              );
            })}
        </GridUl>
      </Row>
    </>
  );
}
