import { useStaticQuery, graphql } from 'gatsby';

export const GetHelpful = () => {
  return useStaticQuery(
    graphql`
      query {
        allStrapiHelpful {
          nodes {
            links {
              id
              link
              text
              external
            }
          }
        }
      }
    `
  ).allStrapiHelpful.nodes;
};
