import React from 'react';
import { getUser, logout } from '../../services/auth';
import Row from '../row/row';
import { navigate } from 'gatsby-link';
import SEO from '../seo';
import { GetClasses } from '../../hooks/classes';
import { Link } from 'gatsby';
import { devices, contentExists } from '../../utils';
import styled from 'styled-components';
import { GetHelpful } from '../../hooks/helpful';
import { FaExternalLinkAlt } from 'react-icons/fa';
import useSWR from 'swr';
import { fetcher } from '../../utils/fetcher';

const Heading = styled.h1`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${devices.tablet} {
    align-items: center;
    flex-direction: row;
  }
`;

const AwardsUl = styled.ul`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  margin-bottom: 50px;
`;

const ExternalLinkIcon = styled(FaExternalLinkAlt)`
  font-size: 10px;
  margin-left: 5px;
`;

export default function Profile() {
  const artistUrl = `https://${getUser().artistUrl}.seintofficial.com`;
  const helpfulContent = GetHelpful();
  const helpfulExists = contentExists(helpfulContent);
  const classes = GetClasses() || [];
  const { data, error, isLoading } = useSWR(
    `${process.env.GATSBY_ADMIN_URL}/users/${getUser().userId}`,
    fetcher
  );

  if (error) {
    return <h2>Failed to load.</h2>;
  }

  if (isLoading) {
    return <h2>Loading...</h2>;
  }

  return (
    <Row>
      <SEO title="Profile" description="" />
      <Heading>
        Your profile
        <a
          href="/"
          onClick={(event) => {
            event.preventDefault();
            logout(() => navigate(`/app/login`));
          }}
          className="button button--no-expand"
        >
          Logout
        </a>
      </Heading>

      <ul style={{ marginBottom: '50px' }}>
        <li>
          <strong>Name:</strong> {`${getUser().firstName} ${getUser().lastName}`}
        </li>
        <li>
          <strong>Artist ID:</strong> {getUser().artistId}
        </li>
        <li>
          <strong>Artist URL:</strong>{' '}
          <a href={artistUrl} target="_blank" rel="noopener noreferrer">
            {artistUrl}
          </a>
        </li>
      </ul>

      <div className="wysiwyg-content h-spacing-bottom">
        <h2>Helpful Links</h2>
        {helpfulExists && (
          <ul>
            {helpfulContent[0]?.links.map(({ id, external, link, text }) => {
              const regex = /^(https?):/;
              const isExternal = regex.test(link);
              return (
                <li key={id}>
                  {external || isExternal ? (
                    <a href={link} target="_blank" rel="noreferrer">
                      {text} <ExternalLinkIcon />
                    </a>
                  ) : (
                    <Link to={link}>{text}</Link>
                  )}
                </li>
              );
            })}
          </ul>
        )}
      </div>

      <h2>Awards Earned</h2>
      <AwardsUl>
        {classes.map((item) => {
          const isComplete = data.completed_classes.some(
            (complete) => complete.id === item.strapiId
          );

          if (isComplete) {
            return (
              <li key={item.id}>
                <img src={item.award?.url} alt={item.title} />
              </li>
            );
          }
        })}
      </AwardsUl>
    </Row>
  );
}
