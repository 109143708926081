import { useStaticQuery, graphql } from 'gatsby';

export const GetWelcome = () => {
  return useStaticQuery(
    graphql`
      query {
        allStrapiWelcome {
          nodes {
            about
            intro
          }
        }
      }
    `
  ).allStrapiWelcome.nodes;
};
