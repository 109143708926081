import { Link } from 'gatsby';
import React from 'react';
import map from '../../assets/images/candyland.jpg';
import styled from 'styled-components';
import { devices } from '../../utils';
import { getUser } from '../../services/auth';
import { GetClasses } from '../../hooks/classes';
import { signs } from './signs';
import { fetcher } from '../../utils/fetcher';
import useSWR from 'swr';

const Sign = styled(Link)`
  transition: scale 0.3s ease-in-out;

  &:focus,
  &:hover {
    transform: scale(1.1);
  }
`;

const Image = styled.img`
  display: block;
  width: 40px;

  @media ${devices.tablet} {
    width: ${(props) => (props.widthTablet ? props.widthTablet : `100px`)};
  }

  @media ${devices.laptopLarge} {
    width: ${(props) => props.width};
  }
`;

const StyledClassMap = styled.div`
  position: relative;
  max-width: 1920px;
  margin: auto;
`;

export default function Map() {
  const { data, error, isLoading } = useSWR(
    `${process.env.GATSBY_ADMIN_URL}/users/${getUser().userId}`,
    fetcher
  );
  const classes = GetClasses();

  if (error) {
    return <h2>Failed to load class map.</h2>;
  }

  if (isLoading) {
    return <h2>Loading...</h2>;
  }

  const completed = data.completed_classes.length === classes.length;

  return (
    <StyledClassMap>
      <img src={map} alt="Candyland Map" style={{ display: 'block' }} />
      {signs.map(({ name, url, image, position, width, widthTablet }) => {
        const isSuccess = url === '/success';
        if (isSuccess && !completed) {
          url = '/app/classes';
        }

        return (
          <Sign
            key={name}
            to={url}
            style={{
              position: 'absolute',
              top: `${position[0]}%`,
              left: `${position[1]}%`,
              display: 'block',
            }}
          >
            <Image src={image} alt={name} width={width} widthTablet={widthTablet} />
          </Sign>
        );
      })}
    </StyledClassMap>
  );
}
