import React from 'react';
import { Link } from 'gatsby';
import { getUser } from '../../services/auth';
import { devices } from '../../utils';
import styled from 'styled-components';
import Row from '../row/row';
import SEO from '../seo';
import rachelImage from '../../assets/images/rachel-jacob.jpg';
import makeupImage from '../../assets/images/makeup-swatch.png';
import signature from '../../assets/images/signature-rachel-jacob.png';
import moon from '../../assets/images/moon.gif';
import { Helmet } from 'react-helmet';
import { FaArrowRight } from 'react-icons/fa';
import { GetWelcome } from '../../hooks/welcome';
import { contentExists } from '../../utils';

const GridContainer = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  margin-bottom: 40px;
  margin-top: 50px;
`;

const About = styled.div`
  max-width: 300px;
  position: relative;
`;

const MakeupImg = styled.img`
  bottom: -20px;
  left: 0;
  max-width: 150px;
  position: absolute;

  @media ${devices.tablet} {
    left: -40px;
  }
`;

const RachelContainer = styled.div`
  @media ${devices.tablet} {
    align-items: center;
    display: grid;
    gap: 20px;
    grid-template-columns: 300px minmax(230px, 600px);
  }
`;

const Welcome = () => {
  const welcomeContent = GetWelcome();
  const welcomeExists = contentExists(welcomeContent);

  return (
    <div>
      <SEO title="Welcome" description="" />
      <Helmet
        bodyAttributes={{
          class: 'welcome-page',
        }}
      />
      <Row marginBottom={50}>
        <GridContainer>
          <div className="wysiwyg-content">
            <h3 className="h-spacing-bottom" style={{ marginTop: '0' }}>
              {getUser().firstName}, Welcome to JU!
            </h3>
            {welcomeExists && welcomeContent[0]?.intro && <p>{welcomeContent[0].intro}</p>}
          </div>

          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/dGoVNaWzujE"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </GridContainer>
      </Row>

      <Row marginBottom={100}>
        <h3 className="h-spacing-bottom">Meet The Leaders</h3>
        <RachelContainer>
          <About>
            <img src={rachelImage} alt="Rachel Jacob" width={300} />
            <MakeupImg src={makeupImage} alt="" width={300} />
          </About>
          <div className="wysiwyg-content">
            {welcomeExists && welcomeContent[0]?.about && <p>{welcomeContent[0].about}</p>}
            <p>
              <img src={signature} alt="" style={{ width: '200px', margin: '0' }} />
            </p>
          </div>
        </RachelContainer>
      </Row>

      <Row>
        <GridContainer>
          <div className="welcome__text wysiwyg-content">
            <h3>Let The Journey Begin!</h3>
            <p>
              <Link
                to="/app/classes"
                className="button"
                style={{ display: 'inline-flex', alignItems: 'center' }}
              >
                Go To Campus Map <FaArrowRight color="#000000" style={{ marginLeft: '10px' }} />
              </Link>
            </p>
            <img src={moon} alt="" />
          </div>
          <div></div>
        </GridContainer>
      </Row>
    </div>
  );
};
export default Welcome;
