import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { FaTasks } from 'react-icons/fa';
import styled from 'styled-components';

const CardLink = styled(Link)`
  background-color: var(--c-theme-gray);
  border-radius: var(--radius);
  box-shadow: 0 0 2px 1px rgb(0 0 0 / 15%);
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ImageContainer = styled.div`
  position: relative;

  ${CardLink}.complete & {
    &::before {
      align-items: center;
      background-color: hsla(0 0% 0% / 0.8);
      border-radius: 5px 5px 0 0;
      bottom: 0;
      color: var(--c-white);
      content: 'Completed!';
      display: flex;
      font-size: 25px;
      font-weight: var(--fw-bold);
      height: 100%;
      justify-content: center;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
    }
  }
`;

const CardImage = styled.img`
  border-radius: var(--radius) var(--radius) 0 0;
  display: block;
  height: 200px;
  object-fit: cover;
  width: 100%;
`;

const CardTitle = styled.h2`
  font-family: var(--ff-body);
  font-size: 1.8rem;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 15px;
`;

const CardStats = styled.ul`
  display: flex;
  margin-bottom: 25px;
`;

const CardStat = styled.li`
  align-items: center;
  color: #868686;
  display: flex;
  font-size: 12px;
  font-weight: var(--fw-bold);
`;

const ProgressContainer = styled.div`
  margin-top: auto;
`;

const ProgressAmount = styled.p`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const Progress = styled.div`
  background-color: var(--c-silver-sand);
  border-radius: 10px;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
  height: 15px;
  overflow: hidden;
`;

const ProgressBar = styled.div`
  background-color: var(--c-primary);
  border-radius: inherit;
  height: 100%;
`;

const StatIcon = styled(FaTasks)`
  color: inherit;
  margin-right: 10px;
`;

export default function Card({
  link,
  image,
  title,
  description,
  totalTasks,
  completedTasks,
  className,
}) {
  const percentage = parseInt((completedTasks / totalTasks) * 100);
  return (
    <CardLink to={link} className={className}>
      <ImageContainer>
        <CardImage src={image} alt="" />
      </ImageContainer>

      <CardContent>
        <CardTitle>{title}</CardTitle>
        <CardStats>
          <CardStat>
            <StatIcon /> {totalTasks} Tasks
          </CardStat>
        </CardStats>
        {description}

        {totalTasks > 0 && (
          <ProgressContainer>
            <ProgressAmount>
              Completed <strong>{`${percentage}%`}</strong>
            </ProgressAmount>
            <Progress>
              <ProgressBar
                role="progressbar"
                aria-valuenow={percentage}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: `${percentage}%` }}
              />
            </Progress>
          </ProgressContainer>
        )}
      </CardContent>
    </CardLink>
  );
}

Card.propTypes = {
  link: PropTypes.string.isRequired,
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  className: PropTypes.string,
  totalTasks: PropTypes.number,
  completedTasks: PropTypes.number,
};
